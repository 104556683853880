import { appEnvironment } from 'src/environments/environment';

export const AppConfig = {
  name: 'Workards',
  version: appEnvironment.currentVersion,
};

export const AppEnv = {
  appCode: appEnvironment.hasOwnProperty('appCode')
    ? appEnvironment.appCode
    : null,
};
